// Font Imports
@font-face {
  font-display: swap;
  font-family: "Epilogue";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/epilogue-v17-latin-500.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url("../fonts/epilogue-v17-latin-500.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
@font-face {
  font-display: swap;
  font-family: "Epilogue";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/epilogue-v17-latin-700.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url("../fonts/epilogue-v17-latin-700.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
// Colours
$clr-white: rgba(255, 255, 255, 1);
$clr-offwhite: rgba(250, 250, 250, 1);
$clr-lightgrey: rgba(173, 173, 173, 1);
$clr-grey: rgba(104, 104, 104, 1);
$clr-black: rgba(21, 21, 21, 1);
$clr-trueblack: rgba(0, 0, 0, 1);

// Fonts
$font-family: "Epilogue", sans-serif;
$font-weight-main: 500;
$font-weight-bold: 700;

// Breakpoints
$breakpoint-desktop: 56.25rem;
