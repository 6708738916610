@use "variables" as var;

@media (prefers-reduced-motion) {
  .navbar__dropdown-icon {
    transition: none;
  }

  .navbar__dropdown-content-wrapper {
    transition: none;
  }
}

@media (min-width: var.$breakpoint-desktop) {
  // Nullify scroll lock class if layout shifts during session
  .prevent-scroll {
    height: auto;
    overflow: visible;
  }

  body {
    display: flex;
    flex-direction: column;
  }

  .header {
    padding: 2em 2.5em;
    gap: 4em;
  }

  .page-container {
    padding: 3em 2em 2em 2em;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .navbar {
    flex: 1;

    &__btn {
      display: none;
    }

    &__menu {
      display: block;
      position: relative;
      top: auto;
      right: auto;
      width: auto;
      height: auto;
      background-color: transparent;
      overflow: visible;
    }

    &__menu-content {
      padding: 0;
      width: auto;
      height: auto;
      min-width: auto;
      max-width: none;
      flex-direction: row;
      justify-content: space-between;
      overflow: visible;
    }

    &__link-group {
      flex-direction: row;
      align-items: center;
      gap: 2.25em;
    }

    &__dropdown {
      position: relative;

      &:hover,
      &:focus-within {
        .navbar__dropdown-content-wrapper {
          visibility: visible;
          max-height: none;
          opacity: 1;
        }

        .navbar__dropdown-icon {
          transform: rotate(180deg);
        }

        .navbar__dropdown-toggle {
          color: var.$clr-black;
        }
      }
    }

    &__dropdown-toggle {
      gap: 0.5em;
    }

    &__dropdown-content-wrapper {
      position: absolute;
      visibility: hidden;
      max-height: none;
      width: max-content;
      z-index: 30;
      padding-top: 2em;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &__dropdown-content {
      padding: 1.5em 1.75em;
      background-color: var.$clr-white;
      box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
    }
  }

  .hero {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4em;
    padding: 1em;

    &__img-wrapper {
      margin: 0;
    }

    &__content {
      text-align: left;
      align-self: flex-end;
    }

    &__heading {
      max-width: 11ch;
    }

    &__copy {
      margin: 0;
      padding: 2em 0;
      max-width: 41ch;
    }

    &__link {
      margin: 0;
    }

    &__client-logos {
      justify-content: flex-start;
      padding: 6em 0 0 0;
    }
  }
}
