@font-face {
  font-display: swap;
  font-family: Epilogue;
  font-style: normal;
  font-weight: 500;
  src: url("epilogue-v17-latin-500.1a0455db.woff2") format("woff2"), url("epilogue-v17-latin-500.5f7cfd95.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Epilogue;
  font-style: normal;
  font-weight: 700;
  src: url("epilogue-v17-latin-700.468aad80.woff2") format("woff2"), url("epilogue-v17-latin-700.60196e6d.ttf") format("truetype");
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  min-height: 100dvh;
}

body {
  background-color: #fafafa;
  font-family: Epilogue, sans-serif;
}

img, picture {
  max-width: 100%;
  display: block;
}

p, span, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.prevent-scroll {
  height: 100dvh;
  overflow: hidden;
}

.header {
  z-index: 0;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em 1.25em;
  display: flex;
  position: relative;
}

.header__logo {
  object-fit: contain;
}

.navbar__menu {
  z-index: 10;
  background-color: #151515bf;
  gap: 2em;
  width: 100%;
  height: 100dvh;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}

.navbar__menu--open {
  display: block;
}

.navbar__menu-content {
  z-index: 20;
  background-color: #fafafa;
  flex-direction: column;
  gap: 2em;
  width: 70vw;
  min-width: 15em;
  max-width: 300px;
  height: 100%;
  margin-left: auto;
  padding: 1.5em;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.navbar__btn {
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: 0;
}

.navbar__btn--close {
  margin-left: auto;
}

.navbar__link-group {
  flex-direction: column;
  order: 1;
  gap: 1.5em;
  padding-inline: 0;
  list-style-type: none;
  display: flex;
}

.navbar__link-group--user {
  text-align: center;
}

.navbar__link {
  color: #686868;
  text-decoration: none;
}

.navbar__link--btn {
  border: 1.5px solid #686868;
  border-radius: 15px;
  padding: .75em 1em;
  display: block;
}

.navbar__dropdown {
  flex-direction: column;
  justify-content: space-between;
  gap: 0;
  display: flex;
}

.navbar__dropdown--open {
  gap: 2em;
}

.navbar__dropdown--open .navbar__dropdown-content-wrapper {
  visibility: visible;
  opacity: 1;
  max-height: none;
  padding: 0 2em 1em;
}

.navbar__dropdown--open .navbar__dropdown-icon {
  transform: rotate(180deg);
}

.navbar__dropdown-icon {
  transition: transform .35s;
  display: inline-flex;
  transform: rotate(0);
}

.navbar__dropdown-toggle {
  cursor: pointer;
  color: #686868;
  background-color: #0000;
  border: 0;
  align-items: center;
  gap: 1em;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0;
  font-family: inherit;
  font-size: 1rem;
  text-decoration: none;
  display: flex;
}

.navbar__dropdown-content-wrapper {
  visibility: hidden;
  opacity: .3;
  max-height: 0;
  padding: 0;
  transition: opacity .25s ease-out;
}

.navbar__dropdown-content {
  flex-direction: column;
  gap: 1.25em;
  padding-inline: 0;
  list-style-type: none;
  display: flex;
}

.navbar__dropdown-link {
  color: #686868;
  align-items: center;
  gap: 1em;
  text-decoration: none;
  display: flex;
}

.navbar__dropdown-link-icon {
  width: 1.25em;
}

.navbar__link:hover, .navbar__dropdown-link:hover, .navbar__dropdown-toggle:hover {
  color: #151515;
}

.hero {
  flex-direction: column-reverse;
  gap: 2.5em;
  display: flex;
}

.hero__content {
  text-align: center;
  padding: .75em;
}

.hero__heading {
  text-align: inherit;
  font-size: clamp(2.25rem, 5.5vw, 5rem);
  font-weight: 700;
}

.hero__copy {
  color: #686868;
  text-align: inherit;
  max-width: 40ch;
  margin: auto;
  padding: 1.25em 0 1.5em;
  font-size: clamp(1rem, 2vw, 1.125rem);
  font-weight: 500;
  line-height: 1.625rem;
}

.hero__link {
  color: #fafafa;
  background-color: #151515;
  border-radius: 1em;
  outline: 1px solid #151515;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  padding: 1em 1.25em;
  font-family: inherit;
  font-size: clamp(1rem, 2vw, 1.125rem);
  text-decoration: none;
  transition: background-color .2s;
  display: block;
}

.hero__link:hover {
  color: #151515;
  background-color: #0000;
}

.hero__link:focus {
  outline: 2px solid #8a2be2;
}

.hero__client-logos {
  justify-content: center;
  align-items: center;
  gap: 2em;
  padding: 3em 0 0;
  list-style-type: none;
  display: flex;
}

.hero__img-wrapper {
  max-width: 450px;
  margin: 0 auto;
}

.hero__img {
  width: 100%;
  height: auto;
}

@media (prefers-reduced-motion) {
  .navbar__dropdown-icon, .navbar__dropdown-content-wrapper {
    transition: none;
  }
}

@media (width >= 56.25rem) {
  .prevent-scroll {
    height: auto;
    overflow: visible;
  }

  body {
    flex-direction: column;
    display: flex;
  }

  .header {
    gap: 4em;
    padding: 2em 2.5em;
  }

  .page-container {
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    padding: 3em 2em 2em;
    display: flex;
  }

  .navbar {
    flex: 1;
  }

  .navbar__btn {
    display: none;
  }

  .navbar__menu {
    background-color: #0000;
    width: auto;
    height: auto;
    display: block;
    position: relative;
    top: auto;
    right: auto;
    overflow: visible;
  }

  .navbar__menu-content {
    flex-direction: row;
    justify-content: space-between;
    width: auto;
    min-width: auto;
    max-width: none;
    height: auto;
    padding: 0;
    overflow: visible;
  }

  .navbar__link-group {
    flex-direction: row;
    align-items: center;
    gap: 2.25em;
  }

  .navbar__dropdown {
    position: relative;
  }

  .navbar__dropdown:hover .navbar__dropdown-content-wrapper, .navbar__dropdown:focus-within .navbar__dropdown-content-wrapper {
    visibility: visible;
    opacity: 1;
    max-height: none;
  }

  .navbar__dropdown:hover .navbar__dropdown-icon, .navbar__dropdown:focus-within .navbar__dropdown-icon {
    transform: rotate(180deg);
  }

  .navbar__dropdown:hover .navbar__dropdown-toggle, .navbar__dropdown:focus-within .navbar__dropdown-toggle {
    color: #151515;
  }

  .navbar__dropdown-toggle {
    gap: .5em;
  }

  .navbar__dropdown-content-wrapper {
    visibility: hidden;
    z-index: 30;
    width: max-content;
    max-height: none;
    padding-top: 2em;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .navbar__dropdown-content {
    background-color: #fff;
    border-radius: 10px;
    padding: 1.5em 1.75em;
    box-shadow: 0 10px 40px #00000026;
  }

  .hero {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4em;
    padding: 1em;
  }

  .hero__img-wrapper {
    margin: 0;
  }

  .hero__content {
    text-align: left;
    align-self: flex-end;
  }

  .hero__heading {
    max-width: 11ch;
  }

  .hero__copy {
    max-width: 41ch;
    margin: 0;
    padding: 2em 0;
  }

  .hero__link {
    margin: 0;
  }

  .hero__client-logos {
    justify-content: flex-start;
    padding: 6em 0 0;
  }
}

/*# sourceMappingURL=index.c056f699.css.map */
