@use "variables" as var;

.header {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em 1.25em;

  &__logo {
    object-fit: contain;
  }
}

.navbar {
  &__menu {
    display: none;
    position: absolute;
    overflow: hidden;
    z-index: 10;
    top: 0;
    right: 0;
    height: 100dvh;
    width: 100%;
    gap: 2em;
    background-color: rgba($color: var.$clr-black, $alpha: 0.75);

    &--open {
      display: block;
    }
  }

  &__menu-content {
    position: relative;
    overflow-y: auto;
    z-index: 20;
    display: flex;
    gap: 2em;
    flex-direction: column;
    min-width: 15em;
    width: 70vw;
    max-width: 300px;
    height: 100%;
    margin-left: auto;
    padding: 1.5em;
    background-color: var.$clr-offwhite;
  }

  &__btn {
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  &__btn--close {
    margin-left: auto;
  }

  &__link-group {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    order: 1;
    list-style-type: none;
    padding-inline: 0;

    &--user {
      text-align: center;
    }
  }

  &__link {
    text-decoration: none;
    color: var.$clr-grey;

    &--btn {
      display: block;
      padding: 0.75em 1em;
      border: 1.5px solid var.$clr-grey;
      border-radius: 15px;
    }
  }

  &__dropdown {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // Gap is initially zero to remove element space
    gap: 0;

    // On mobile, when open state applied, show the dropdown
    &--open {
      gap: 2em;

      .navbar__dropdown-content-wrapper {
        visibility: visible;
        max-height: none;
        padding: 0 2em 1em 2em;
        opacity: 1;
      }

      .navbar__dropdown-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__dropdown-icon {
    display: inline-flex;
    transform: rotate(0deg);
    transition: transform 350ms ease;
  }

  &__dropdown-toggle {
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 1em;
    background-color: transparent;
    border: 0;
    font-family: inherit;
    font-size: 1rem;
    padding: 0;
    text-decoration: none;
    color: var.$clr-grey;
    width: fit-content;
  }

  &__dropdown-content-wrapper {
    // Visibility, max-height and padding are set to remove element space but allow animation
    visibility: hidden;
    max-height: 0;
    padding: 0;
    opacity: 0.3;
    transition: opacity 250ms ease-out;
  }

  &__dropdown-content {
    display: flex;
    padding-inline: 0;
    flex-direction: column;
    list-style-type: none;
    gap: 1.25em;
  }

  &__dropdown-link {
    text-decoration: none;
    color: var.$clr-grey;
    display: flex;
    align-items: center;
    gap: 1em;
  }

  &__dropdown-link-icon {
    width: 1.25em;
  }

  &__link,
  &__dropdown-link,
  &__dropdown-toggle {
    &:hover {
      color: var.$clr-black;
    }
  }
}

.hero {
  display: flex;
  flex-direction: column-reverse;
  gap: 2.5em;

  &__content {
    padding: 0.75em;
    text-align: center;
  }

  &__heading {
    font-size: clamp(2.25rem, 5.5vw, 5rem);
    font-weight: var.$font-weight-bold;
    text-align: inherit;
  }

  &__copy {
    color: var.$clr-grey;
    padding: 1.25em 0 1.5em 0;
    font-weight: 500;
    line-height: 1.625rem;
    font-size: clamp(1rem, 2vw, 1.125rem);
    max-width: 40ch;
    margin: auto;
    text-align: inherit;
  }

  &__link {
    display: block;
    width: fit-content;
    margin: 0 auto;
    text-decoration: none;
    font-family: inherit;
    font-size: clamp(1rem, 2vw, 1.125rem);
    color: var.$clr-offwhite;
    background-color: var.$clr-black;
    padding: 1em 1.25em;
    border-radius: 1em;
    outline: 1px solid var.$clr-black;
    transition: background-color 200ms ease;

    &:hover {
      background-color: transparent;
      color: var.$clr-black;
    }

    &:focus {
      outline: solid 2px blueviolet;
    }
  }

  &__client-logos {
    padding: 3em 0 0 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
  }

  &__img-wrapper {
    max-width: 450px;
    margin: 0 auto;
  }

  &__img {
    width: 100%;
    height: auto;
  }
}
