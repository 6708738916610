@use "variables" as var;

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body {
  min-height: 100dvh;
}

body {
  font-family: var.$font-family;
  background-color: var.$clr-offwhite;
}

img,
picture {
  display: block;
  max-width: 100%;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

// Visually hide element but leave it accessible for screen readers
// https://www.a11ywithlindsey.com/blog/introduction-accessible-labeling
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// Applied on the body when mobile menu is visible & focused
// Prevents scrolling of body or scrolling past menu
.prevent-scroll {
  height: 100dvh;
  overflow: hidden;
}
